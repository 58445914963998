import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/logo.svg";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";

export const Footer = () => {
  return (
      <footer className="footer">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col size={12} sm={7} className="text-center">
              <div className="social-icon">
                {/* <a href="#"><img src={navIcon1} alt="Icon" /></a> */}
                {/* <a href="#"><img src={navIcon2} alt="Icon" /></a> */}
                {/* <a href="#"><img src={navIcon3} alt="Icon" /></a> */}
                  <p className="copyright">Copyright © 2025. All Rights Reserved - v1.0.4 - Designed &
                      Developed by Tshepo Boikanyo
                  </p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
  )
}
